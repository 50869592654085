<script>
	import { closeModal, modals } from 'svelte-modals';
	import { onDestroy } from 'svelte';
	import { createEventDispatcher } from 'svelte';

	export let id = '';
	export let hideHeader = false;
	export let hideModalClose = false;
	export let customHeaderHeight = 'auto';
	export let customHeaderWidth = 'auto';
	export let customModalWidth = '62rem';
	export let customModalScale = '';

	const hasCustomWidth = customHeaderWidth !== 'auto' ? true : false;

	const dispatch = createEventDispatcher();

	/**
	 * Event handler for keyboard events.
	 *
	 * @param {KeyboardEvent} evt - The keyboard event object.
	 * @returns {void}
	 */
	const evtHandler = (evt) => {
		dispatch('keydown', evt);
		return;
	};

	onDestroy(() => {
		// TODO: common utitlity to handle this.
		if (typeof document !== 'undefined') {
			modals.subscribe((modals) => {
				if (modals.length > 0) {
					document.documentElement.style.setProperty('overflow-y', 'hidden');
				} else {
					document.documentElement.style.setProperty('overflow-y', 'visible');
				}
			});
		}
	});
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<aside
	{id}
	on:keydown={evtHandler}
	role="dialog"
	class="{customModalScale} abcmouse-modal"
	style={`--modal-width: ${customModalWidth};`}
>
	<header
		class="abcmouse-popup-header panel-header rounded-t-[25px] bg-[#064EB6]"
		class:hidden={hideHeader}
		style={`--header-height: ${customHeaderHeight};
            --header-width: ${customHeaderWidth};
            --header-mb-width: ${hasCustomWidth ? 'calc(var(--header-width) - 24rem)' : ''};
            ${hasCustomWidth ? 'margin: auto;' : ''}`}
	>
		<slot name="modal-header-title" />
		<slot name="modal-focus" />

		{#if !hideModalClose}
			<img
				id="modal-close-button"
				class="modal-close-button"
				height="10"
				width="10"
				src="/assets-mkt/template/modal-close-button/abc_popup_close_btn.svg"
				on:click={closeModal}
				on:keydown={closeModal}
				alt="Modal Close"
			/>
		{:else}
			<slot name="modal-close-button" />
		{/if}
	</header>
	<slot name="modal-content" />
	<slot name="modal-focus" />
</aside>
